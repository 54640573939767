import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SSHpage = () => (
  <Layout>
    <SEO title="How to add SSH keys to gitlab." />
    <h1>SSH keys and Gitlab</h1>
    <p>Here is the <a href="https://docs.gitlab.com/ee/gitlab-basics/create-your-ssh-keys.html">Gitlab official docs on ssh keys</a></p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SSHpage
